.complex-card {
  background-color: var(--bg-dark-grey-color);
  color: var(--txt-white-color);
  border: 2px solid var(--bg-black-color);
  border-radius: 8px;
  overflow: hidden;
}

.card-header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 2px solid var(--bg-black-color);
}

.card-header img {
  width: 100px;
  height: 100px;
  margin-right: 16px;
  border-radius: 50%;
}
