.whats-app-chat-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
}

.whats-app-chat-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #25d366;
  color: white;
}

.whats-app-message-icon {
  font-size: 24px;
}

.whats-app-close-icon {
  font-size: 20px;
  cursor: pointer;
}

.whats-app-chat-popup-body {
  padding: 20px;
  text-align: center;
}

.whats-app-chat-popup-footer {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.whats-app-chat-button {
  display: flex;
  align-items: center;
  background-color: #25d366;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.whats-app-send-icon {
  margin-right: 8px;
}
