@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Tenor Sans', sans-serif;
  text-decoration: none;
  scroll-behavior: smooth;
  word-break: normal;
}

:root {
  --primary-color: #fa4549;
  --white-color: #fff;

  /* box */
  --bg-black-color: #000;
  --bg-dark-grey-color: #141414;

  /* text */
  --txt-white-color: #f5f5f6;
  --txt-grey-color: #94969c;

  /* text-size */
  --txt-header-size: 72px;
  --txt-header-lineHeight: 90px;
  --txt-title-size: 56px;
  --txt-title-lineHeight: 72px;
  --txt-subtitle-size: 40px;
  --txt-subtitle-lineHeight: 48px;
  --txt-descr-size: 18px;
  --txt-descr-lineHeight: 28px;

  /* text-font*/
  --txt-tenor-font: 'Tenor Sans', sans-serif;
  --txt-inter-font: 'Inter', sans-serif;

  /* border */
  --bg-main-border-color: 'linear-gradient(45deg, purple, orange)';
}

body {
  background: var(--bg-black-color);
}

.header {
  color: var(--txt-white-color);
  font-size: var(--txt-header-size);
  line-height: var(--txt-header-lineHeight);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.432px;
}

.title {
  color: var(--txt-white-color);
  font-size: var(--txt-title-size);
  line-height: var(--txt-title-lineHeight);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.28px;
}

.subtitle {
  color: var(--txt-white-color);
  font-size: var(--txt-subtitle-size);
  line-height: var(--txt-subtitle-lineHeight);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.descr {
  font-size: var(--txt-descr-size);
  line-height: var(--txt-descr-lineHeight);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.09px;
}

@media (max-width: 1200px) {
  /* size -4px, lineHeight -8px */
  :root {
    /* text-size */
    --txt-header-size: 60px;
    --txt-header-lineHeight: 72px;
    --txt-title-size: 44px;
    --txt-title-lineHeight: 52px;
    --txt-subtitle-size: 36px;
    --txt-subtitle-lineHeight: 40px;
    --txt-descr-size: 16px;
    --txt-descr-lineHeight: 24px;
  }
}

@media (max-width: 900px) {
  /* size -4px, lineHeight -8px */
  :root {
    /* text-size */
    --txt-header-size: 44px;
    --txt-header-lineHeight: 56px;
    --txt-title-size: 32px;
    --txt-title-lineHeight: 40px;
    --txt-subtitle-size: 34px;
    --txt-subtitle-lineHeight: 36px;
    --txt-descr-size: 16px;
    --txt-descr-lineHeight: 24px;
  }
}

@media (max-width: 600px) {
  /* size -4px, lineHeight -8px */
  :root {
    /* text-size */
    --txt-header-size: 32px;
    --txt-header-lineHeight: 40px;
    --txt-title-size: 24px;
    --txt-title-lineHeight: 32px;
    --txt-subtitle-size: 24px;
    --txt-subtitle-lineHeight: 32px;
    --txt-descr-size: 16px;
    --txt-descr-lineHeight: 24px;
  }
}
